var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _vm.tagValueList.length > 0
            ? _c(
                "span",
                _vm._l(_vm.tagValueList, function (tag, index) {
                  return _c(
                    "el-tag",
                    {
                      key: index,
                      attrs: { closable: "", "disable-transitions": false },
                      on: {
                        close: function ($event) {
                          return _vm.handleTagDelete(tag)
                        },
                      },
                    },
                    [_vm._v("\n        " + _vm._s(tag) + "\n      ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.inputVisible
            ? [
                _c("el-autocomplete", {
                  ref: "saveTagInput",
                  staticClass: "input-new-tag",
                  attrs: {
                    "fetch-suggestions": _vm.querySearch,
                    size: "small",
                    maxlength: 20,
                    clearable: "",
                    "select-when-unmatched": true,
                    placeholder: _vm.$t("commons.pleaseInput"),
                  },
                  on: { select: _vm.handleInputConfirm, blur: _vm.inputBlur },
                  model: {
                    value: _vm.inputValue,
                    callback: function ($$v) {
                      _vm.inputValue = $$v
                    },
                    expression: "inputValue",
                  },
                }),
              ]
            : _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { size: "small" },
                  on: { click: _vm.showInput },
                },
                [_vm._v("+ " + _vm._s(_vm.$t("tag.create")))]
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }