<template>
  <div>
    <el-row>
      <span v-if="tagValueList.length > 0">
        <el-tag
          :key="index"
          v-for="(tag, index) in tagValueList"
          closable
          :disable-transitions="false"
          @close="handleTagDelete(tag)"
        >
          {{ tag }}
        </el-tag>
      </span>
      <template v-if="inputVisible">
        <el-autocomplete
          class="input-new-tag"
          v-model="inputValue"
          :fetch-suggestions="querySearch"
          ref="saveTagInput"
          size="small"
          :maxlength="20"
          clearable
          :select-when-unmatched="true"
          :placeholder="$t('commons.pleaseInput')"
          @select="handleInputConfirm"
          @blur="inputBlur"
        >
        </el-autocomplete>
        <!-- <el-button
          type="success"
          @click="handleInputConfirm"
          icon="el-icon-check"
          size="mini"
          style="margin-left: 10px"
          circle
        ></el-button>
        <el-button
          type="danger"
          icon="el-icon-close"
          @click="inputVisible = false"
          size="mini"
          style="margin-left: 10px"
          circle
        ></el-button> -->
      </template>
      <el-button v-else class="button-new-tag" size="small" @click="showInput"
        >+ {{ $t("tag.create") }}</el-button
      >
    </el-row>
  </div>
</template>

<script>
import { getTagList } from "@/api/ruge/tag/tag";

export default {
  name: "TagBind",
  props: {
    tagValueList: {
      type: Array,
      required: true,
    },
    tagType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tagList: [],
      suggestList: null, //缓存建议列表
      inputVisible: false,
      inputValue: "",
      listQuery: {
        //分页参数
        current: 1,
        rowCount: 20,
        resultMode: 2,
        tagType: null,
      },
    };
  },
  methods: {
    handleTagDelete(tag) {
      this.$emit("tagDelete", tag, this.tagValueList);

      /*// 删除页面标签
      if(this.tagList.findIndex(item => item.value == tag) == -1){
        this.tagList.push({"value":tag});
      }
      this.tagValueList.splice(this.tagValueList.indexOf(tag), 1);*/
    },
    initTagList() {
      this.listQuery.tagType = this.tagType;
      return getTagList(this.listQuery)
        .then((res) => {
          this.tagList = [];
          let tagList = [];
          res.rows.map((v) => {
            tagList.push(v.tagName);
          });
          let tagValueList = this.tagValueList;
          let set1 = new Set(tagList);
          let set2 = new Set(tagValueList);
          let dfrcset = [...new Set([...set1].filter((x) => !set2.has(x)))];
          dfrcset.map((v) => {
            this.tagList.push({ value: v });
          });
        })
        .catch((error) => {
          console.log(`查询失败，原因=> ${error}`);
        });
    },

    showInput() {
      this.inputValue = "";
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    inputBlur() {
      // 延迟关闭，不然在正常选择的时候会有问题。
      setTimeout(() => {
        // this.inputValue = "";
        // this.inputVisible = false;
        this.handleInputConfirm();
      }, 200);
    },
    handleInputConfirm() {
      let inputValue = this.inputValue.trim();
      if (inputValue.includes(",")) {
        this.$message.error("标签中不能含有逗号！");
        return;
      }
      if (inputValue) {
        this.$emit("tagAdd", inputValue, this.tagValueList);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    querySearch(queryString, cb) {
      if (!this.inputValue && this.suggestList) {
        cb(this.suggestList);
        return;
      }
      this.initTagList().then(() => {
        let tagList = this.tagList;
        let results = queryString
          ? tagList.filter(this.createFilter(queryString))
          : tagList;
        // 调用 callback 返回建议列表的数据
        !this.suggestList && (this.suggestList = results);
        cb(results);
      });
    },
    createFilter(queryString) {
      return (tag) => {
        return tag.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      this.inputValue = item.value.trim();
    },
  },
};
</script>

<style scoped>
.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
