import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

// 渲染动态form表单的值
export function dynamicLabelRender(item) {
  console.log('item', item);
  const { key, propsList } = item;
  const defaultValue = propsList.filter((single) => single.key === 'default')[0]
    .value;
  if (['selector', 'companySelector'].includes(key)) {
    const multiple = propsList.filter((single) => single.key === 'multiple')[0]
      .value;
    const itemConfig = propsList.filter(
      (item) => item.key === 'options' || item.key === 'companyOptions'
    )[0].config;
    const { type, optionList, serviceUrl, valueKey, labelKey, classifyCode } =
      itemConfig;
    if (type === 'static') {
      if (multiple) {
        const valueList =
          typeof defaultValue === 'object'
            ? defaultValue
            : defaultValue.split(',');
        const resultList = [];
        valueList.forEach((item) => {
          const filterList = optionList.filter(
            (single) => single.value == item
          );
          if (filterList && filterList.length) {
            resultList.push(filterList[0].label);
          }
        });
        return resultList.length ? resultList.join(',') : '-';
      } else {
        const filterList = optionList.filter(
          (single) => single.value == defaultValue
        );
        return filterList && filterList.length
          ? filterList[0].label
          : defaultValue || '-';
      }
    } else if (type === 'inner') {
      request({
        url: serviceUrl,
        method: 'get',
      })
        .then((res) => {
          let result = '';
          if (multiple) {
            const valueList =
              typeof defaultValue === 'object'
                ? defaultValue
                : defaultValue.split(',');
            const resultList = [];
            valueList.forEach((item) => {
              const filterTemp = res.filter(
                (single) => single[valueKey] == item
              );
              if (filterTemp && filterTemp.length) {
                resultList.push(filterTemp[0][labelKey]);
              }
            });
            result = resultList.join(',');
          } else {
            const filetrList = res.filter(
              (item) => item[valueKey] == defaultValue
            )[0];
            result = filetrList ? filetrList[labelKey] : defaultValue;
          }
          $(`#${item.id}`).text(result || '-');
        })
        .catch(() => {
          $(`#${item.id}`).text(defaultValue);
        });
    } else if (type === 'classify') {
      request({
        url:
          envInfo.bgApp.lookupPath +
          `/tenant/lookup/item/find/list?classifyCode=${classifyCode}`,
        method: 'get',
      }).then((res) => {
        let result = '';
        if (multiple) {
          const valueList =
            typeof defaultValue === 'object'
              ? defaultValue
              : defaultValue.split(',');
          console.log('xxx', valueList);
          const resultList = [];
          valueList.forEach((item) => {
            const filterTemp = res.filter(
              (single) => single['itemCode'] == item
            );
            if (filterTemp && filterTemp.length) {
              resultList.push(filterTemp[0]['itemName']);
            }
          });
          result = resultList.join(',');
        } else {
          const filetrList = res.filter(
            (item) => item['itemCode'] == defaultValue
          )[0];
          result = filetrList ? filetrList['itemName'] : defaultValue;
        }
        $(`#${item.id}`).text(result || '-');
      });
    }
  } else {
    return defaultValue ? defaultValue.replace(/\n/g, '</br>') : '-';
  }
}
